<template>
  <div class="flex-container">
    <h4 class="item">Oops!</h4>
    <div class="item">
      It looks like your service with us has been canceled or temporary
      inactive. If there's anything you need help with, our support team is here
      for you.
    </div>
    <div class="item">
      Please drop an email at
      <a
        class="contact-support-block__support-email"
        :href="`mailto:support@social-wonder.com?subject=Help%20needed&body=${mailToHref}`"
        >support@social-wonder.com</a
      >
      with your concerns or questions. We'd be more than happy to assist you
      with your request.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Index",
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),

    mailToHref() {
      const mailBody = `Name: ${
        this.getCurrentSmbCustomer.personal_name || ""
      }\nEmail: ${this.getCurrentSmbCustomer.email || ""}\nPhone: ${
        this.getCurrentSmbCustomer.primary_phone || ""
      }\n`;
      return encodeURI(mailBody);
    },
  },
  methods: {
    ...mapActions("smbClientSelection", ["fetchSmbClientInfo"]),
  },
  async created() {
    if (this.$route.params.id && !this.getCurrentSmbCustomer.id) {
      await this.fetchSmbClientInfo(this.$route.params.id);
    }
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .item {
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
